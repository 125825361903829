import { coreServices } from '@/services/coreServices';

export const dashboardServices = {
  getDataKpi(studyId) {
    const params = { studyId: studyId };
    return coreServices.get('/dashboards/get-data-kpi/', coreServices.getRequestHeader(), params);
  },
  /**
   * Get values for the KPI cards
   * @param {object} params Filters for the KPI cards
   * @return axios object
   */
  getKpiDashboardValues(params) {
    return coreServices.get('/dashboards/kpis/', coreServices.getRequestHeader(), params);
  },
  getDataDashboard(params) {
    return coreServices.get('/dashboards/data-dashboard/', coreServices.getRequestHeader(), params);
  },
};
