<template>
  <div class="main-container">
    <app-header />
    <nav-bar />
    <div class="content">
      <overlay-loader :loading="loading"></overlay-loader>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <v-card class="container">
              <div class="row">
                <div class="col-12">
                  <h2>List of KPIs</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-4">
                  <v-card-text>
                      <v-select
                        v-model="kpis_filters.study_id"
                        :items="study_items"
                        item-text="name"
                        label="Select Study"
                        return-object
                        underlined
                        clearable
                        @click:clear="kpis_filters.study_id = null"
                        @change="getKpis()"
                      ></v-select>
                    </v-card-text>
                    <v-card-text>
                      <v-select
                        v-model="kpis_filters.owners"
                        :items="owners"
                        item-text="first_name"
                        label="Select owners"
                        return-object
                        underlined
                        multiple
                        clearable
                        @click:clear="kpis_filters.owners = []"
                        @change="getKpis()"
                      >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.first_name }} {{ data.item.last_name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.first_name }} {{ data.item.last_name }}
                      </template>
                    </v-select>
                    </v-card-text>
                    <v-card-text>
                      <v-select
                        v-model="kpis_filters.is_urgent"
                        :items="['All', 'Yes', 'No']"
                        label="Urgent"
                        return-object
                        underlined
                        @change="getKpis()"
                      ></v-select>
                    </v-card-text>
                </div>
                <div class="col-12 col-sm-8">
                  <div class="row">
                    <div class="col-12 col-xs-6 col-sm-4 col-lg-3 mb-3"
                    style="width: 200px; height: 134px;"
                    v-for="kpi in filterKpis(1)" :key="kpi.id">
                    <v-card class="text-center kpi-card" :class="getKpiCardColors(kpi.percentage)">
                      <span class="label">{{ kpi.name }}</span>
                      <br>
                      <span class="percentage">{{ parseFloat(kpi.percentage).toFixed(2) }}%</span>
                    </v-card>
                    </div>
                  </div>
                  <v-row>
                    <v-col cols="12" class="text-center pb-0">
                      <img src="../assets/img/kpi_diagram_blank_top.png" alt="KPI Diagram" class="kpi-diagram-img">
                    </v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="2" class="text-center kpi-value-col pt-0">
                      <span><strong>{{ myLicence.kpi_pre_tmfy }} days</strong></span>
                    </v-col>
                    <v-col cols="3" class="text-center kpi-value-col pt-0">
                      <span>
                        <strong>{{ myLicence.kpi_management_not_urgent }} or
                          {{ myLicence.kpi_management_urgent }} days</strong>
                      </span>
                    </v-col>
                    <v-col cols="3" class="text-center kpi-value-col pt-0">
                      <span>
                        <strong>{{ myLicence.kpi_approval_not_urgent }} or
                          {{ myLicence.kpi_approval_urgent }} days</strong>
                      </span>
                    </v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="12" class="text-center pb-0">
                      <img src="../assets/img/kpi_diagram_blank_bottom.png" alt="KPI Diagram" class="kpi-diagram-img">
                    </v-col>
                    <v-col cols="12" class="text-center kpi-value-col pt-0">
                      <span><strong>{{ myLicence.kpi_total_time }} days</strong></span>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-row>
                <v-col cols="12" sm="8" class="offset-4">
                  <hr>
                </v-col>
              </v-row>
              <div class="row pb-5">
                <v-col cols="12" sm="8" class="offset-4">
                  <v-row>
                    <div class="col-12 col-xs-6 col-sm-4 col-lg-3 mb-3"
                    style="width: 200px; height: 134px;"
                    v-for="kpi in filterKpis(2)" :key="kpi.id">
                      <v-card class="text-center kpi-card" :class="getKpiCardColors(kpi.percentage)">
                        <span class="label">{{ kpi.name }}</span>
                        <br>
                        <span class="percentage">{{ parseFloat(kpi.percentage).toFixed(2) }}%</span>
                      </v-card>
                    </div>
                  </v-row>
                </v-col>
              </div>
            </v-card>
            <v-card class="container mb-4">
              <div class="row">
                <div class="col-12">
                  <h2>Chart</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <v-card-text>
                      <v-select
                        v-model="study_model"
                        :items="study_items"
                        item-text="name"
                        label="Select Study"
                        required
                        return-object
                        underlined
                        clearable
                        @click:clear="study_model={}"
                        @change="getStudyId()"
                      ></v-select>
                    </v-card-text>
                  </div>
                  <div class="row">
                    <v-card-text>
                      <v-select
                        v-model="studyCountry_model"
                        :items="study_country_items"
                        item-text="name"
                        label="Select Country"
                        required
                        return-object
                        clearable
                        underlined
                        @click:clear="studyCountry_model={}"
                        @change="getStudyCountryId()"
                      ></v-select>
                    </v-card-text>
                  </div>
                  <!-- <div class="row">
                    <v-card-text>
                      <v-select
                        v-model="date_model"
                        :items="date"
                        item-text="date_name"
                        return-object
                        underlined
                        clearable
                        @click:clear="dateClear()"
                        label="Select Date"
                        @change="getFileDates()"
                      ></v-select>
                    </v-card-text>
                  </div> -->
                  <!-- <div class="row">
                    <v-card-text>
                      <v-select
                        v-model="indexer_model"
                        :items="owners"
                        item-text="first_name"
                        return-object
                        underlined
                        clearable
                        @click:clear="indexerClear()"
                        label="Select Indexer"
                        @change="getIndexerId()"
                      >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.first_name }} {{ data.item.last_name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.first_name }} {{ data.item.last_name }}
                      </template>
                    </v-select>
                    </v-card-text>
                  </div> -->
                  <div class="row">
                    <v-card-text>
                      <v-autocomplete
                        v-model="zone_model"
                        :items="zone_items"
                        item-text="name"
                        label="Select Zone"
                        required
                        return-object
                        underlined
                        clearable
                        @click:clear="zoneClear()"
                        @change="getZoneId()">
                        <template v-slot:item="{ item }" >{{ item.number }} {{ item.name }}</template>
                      </v-autocomplete>
                    </v-card-text>
                  </div>
                  <div class="row">
                    <v-card-text>
                      <v-autocomplete
                        v-model="section_model"
                        :items="filterSectionByZone()"
                        item-text="name"
                        label="Select Section"
                        required
                        clearable
                        underlined
                        @click:clear="sectionClear()"
                        return-object
                        @change="getSectionId()">
                        <template v-slot:item="{ item }" >{{ item.number }} {{ item.name }}</template>
                      </v-autocomplete>
                    </v-card-text>
                  </div>
                  <div class="row">
                    <v-card-text>
                      <v-autocomplete
                        v-model="artifact_model"
                        clearable
                        @click:clear="artifactClear()"
                        :items="filterArtifactBySection()"
                        item-text="name"
                        underlined
                        return-object
                        label="Select Artifact"
                        @change="getArtifactId()">
                        <template v-slot:item="{ item }" >{{ item.number }} {{ item.name }}</template>
                      </v-autocomplete>
                    </v-card-text>
                  </div>
                </div>
                <div class="col-md-8" style="height:700px;">
                  <canvas id="Doughnut"></canvas>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from 'chart.js/auto';
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import { accountServices } from '@/services/accountServices';
import { masterdataServices } from '@/services/masterdataServices';
import { dashboardServices } from '@/services/dashboardServices';
import { fileServices } from '../services/fileServices';
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';

export default {
  name: 'Dashboards',
  components: {
    AppHeader,
    NavBar,
    OverlayLoader,
  },
  data() {
    return {
      chartId: 'Doughnut',
      zone_items: [],
      section_items: [],
      artifact_items: [],
      study_country_items: [],
      study_items: [],
      loading: false,
      chartData: null,
      myChart: null,
      chartStatusColors: ['#2AB3BA', '#fff59d', '#EF9A9A', '#A8CC47', '#ffcc80', '#888'],
      // date: [],
      // indexer: [],
      study_model: {},
      // indexer_model: {},
      studyCountry_model: {},
      section_model: {},
      zone_model: {},
      artifact_model: {},
      // date_model: {},
      study_country_id: '',
      study_id: '',
      zone_id: '',
      section_id: '',
      artifact_id: '',
      kpis: [
        {
          name: 'Pre-Tmfy', percentage: 0.00, is_visible: true, id: 'pre_tmfy', group: 1,
        },
        {
          name: 'Management', percentage: 0.00, is_visible: true, id: 'management', group: 1,
        },
        {
          name: 'Approve', percentage: 0.00, is_visible: true, id: 'approve', group: 1,
        },
        {
          name: 'Total time', percentage: 0.00, is_visible: true, id: 'total_time', group: 1,
        },
        {
          name: 'Expected documents', percentage: 0.00, is_visible: true, id: 'expected_documents', group: 2,
        },
      ],
      owners: [],
      myLicence: {},
      kpis_filters: {
        study_id: null,
        owners: [],
        is_urgent: 'All',
      },
    };
  },
  mounted() {
    this.getMyLicence();
    this.getMasterdata();
    this.getStudies();
    this.getStudyCountries();
    // this.getDates();
    const params = {
      study_id: '',
      study_country_id: '',
      // date: '',
      // indexer: '',
      zone: '',
      section: '',
      artifact: '',
    };
    this.chartData = {
      type: 'pie',
      data: {
        datasets: [{
          backgroundColor: this.chartStatusColors,
        }],
      },
      options: {
        legend: {
          position: 'right',
          display: true,
        },
        plugins: {
          labels: {
            render: 'percentaje',
            precision: 3,
          },
          datalabels: {
            precision: 3,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
    this.createChart(this.chartId, params);
    this.getKpisOwners();
  },
  methods: {
    /** Get css classes for the KPI card depending on the percentage.
     * @param {int} percentage
     */
    getKpiCardColors(percentage) {
      if (percentage >= 95.00) return 'green text-white';
      if (percentage > 90.00 && percentage < 95.00) return 'yellow text-dark';
      if (percentage <= 90.00) return 'red text-white';
      return '';
    },
    /** Filter the KPIs that this user can view.
     * Each KPI in the list contains is_visible so a role can be excluded.
     * Example for single role: is_visible: roleManager.isAdmin()
     * @param {int} group Group of KPI that want to show
     */
    filterKpis(group) {
      return this.kpis.filter((item) => item.is_visible && item.group === group);
    },
    /** Get KPI values from backend */
    getKpis() {
      if (this.kpis_filters.study_id === null) {
        this.kpis.forEach((item) => {
          // eslint-disable-next-line
          item.percentage = 0;
        });
        return;
      }
      this.loading = true;
      const f = {
        study_id: this.kpis_filters.study_id ? this.kpis_filters.study_id.id : null,
        is_urgent: this.kpis_filters.is_urgent === 'All' ? null : this.kpis_filters.is_urgent === 'Yes',
        owners: this.kpis_filters.owners.length > 0 ? this.kpis_filters.owners.map((x) => x.id).join(',') : [],
      };

      dashboardServices.getKpiDashboardValues(f).then((response) => {
        Object.keys(response.data).forEach((item) => {
          const obj = this.kpis.findIndex((item2) => item2.id === item);
          this.kpis[obj].percentage = response.data[item];
        });
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    /** Get all possible owners from data (files) objects */
    getKpisOwners() {
      fileServices.getKpiOwners().then((response) => {
        this.owners = response.data;
      });
    },
    createChart(chartId, params) {
      dashboardServices.getDataDashboard(params)
        .then((response) => {
          const statusNames = response.data.status_names;
          const statusValues = response.data.status_value;
          const chartCopy = { ...this.chartData };
          chartCopy.data.datasets[0].data = statusValues;
          chartCopy.data.labels = statusNames;
          const labels = {
            render: 'percentage',
            fontColor: this.chartStatusColors,
            precision: 2,
          };
          chartCopy.options.plugins.labels = labels;
          this.myChart = null;
          const ctx = document.getElementById(chartId).getContext('2d');
          this.myChart = new Chart(ctx, chartCopy);
        });
    },
    getStudies() {
      fileServices.getStudies().then((response) => { this.study_items = response.data; });
    },
    getStudyId() {
      this.study_id = this.study_model ? this.study_model.id : null;
      this.refreshChart();
    },
    getStudyCountries() {
      fileServices.getStudyCountryList()
        .then((response) => { this.study_country_items = response.data; })
        .catch(() => {});
    },
    getStudyCountryId() {
      if (!this.studyCountry_model) return;
      this.study_country_id = this.studyCountry_model.id;
      this.refreshChart();
    },
    // getDates() {
    //   fileServices.getFileDates()
    //     .then((response) => {
    //       this.date = response.data.map((item) => {
    //         const i = {};
    //         const cdate = item.split('T')[0].split('-');
    //         i.date_name = `${cdate[0]}/${cdate[1]}/${cdate[2]}`;
    //         i.date_id = item;
    //         return i;
    //       });
    //     });
    // },
    // getFileDates() {
    //   this.refreshChart();
    // },
    // getIndexerId() {
    //   if (!this.indexer_model) this.indexer_model = {};
    //   this.indexer_id = this.indexer_model.id;
    //   this.refreshChart();
    // },
    // indexerClear() {
    //   this.indexer_model = {};
    // },
    getZoneId() {
      if (!this.zone_model) this.zone_model = {};
      this.zone_id = this.zone_model.id;
      this.refreshChart();
    },
    zoneClear() {
      this.zone_model = {};
    },
    filterSectionByZone() {
      return this.section_items;
      // return this.section_items.filter((item) => item.id === this.zone_model.section_id);
    },
    getSectionId() {
      if (!this.section_model) this.section_model = {};
      this.section_id = this.section_model.id;
      this.refreshChart();
    },
    sectionClear() {
      this.section_model = {};
    },
    filterArtifactBySection() {
      return this.artifact_items;
      // return this.artifact_items.filter((item) => item.id === this.section_model.artifact_id);
    },
    getArtifactId() {
      if (!this.artifact_model) this.artifact_model = {};
      this.artifact_id = this.artifact_model.id;
      this.refreshChart();
    },
    artifactClear() {
      this.artifact_model = {};
    },
    // dateClear() {
    //   this.date_model = {};
    // },
    getMyLicence() {
      accountServices.getMyLicence()
        .then((response) => { this.myLicence = response.data; })
        .catch(() => {});
    },
    getMasterdata() {
      masterdataServices.getMasterdata()
        .then((response) => {
          this.zone_items = response.data.zones;
          this.section_items = response.data.sections;
          this.artifact_items = response.data.artifacts;
        })
        .catch(() => {});
    },
    destroyChart(chart) {
      if (chart) chart.destroy();
    },
    refreshChart() {
      // if (!this.date_model) this.date_model = {};
      // if (!this.date_model.date_id) this.date_model.date_id = '';
      const params = {
        study_id: this.study_id,
        study_country_id: this.study_country_id,
        // date: this.date_model.date_id,
        // indexer: this.indexer_id,
        zone: this.zone_id,
        section: this.section_id,
        artifact: this.artifact_id,
      };
      this.destroyChart(this.myChart);
      this.createChart(this.chartId, params);
    },
  },
};
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/_variables';
  .kpi-diagram-img {
    max-width: 100%;
  }
  .kpi-card {
    padding: 10px;
    .label {
      font-size: 18px;
    }
    .percentage {
      font-size: 30px;
    }
    &.green {
      background-color: $tfe-green;
      * {
        color: white;
      }
    }
    &.yellow {
      background-color: $tfe-yellow;
    }
    &.red {
      background-color: $tfe-red;
      * {
        color: white;
      }
    }
  }
</style>
